<template>
    <v-sheet elevation="2">
        <v-toolbar dense color="primary" dark flat>
            <v-toolbar-title delse>Pacientes próximos a egresar</v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="listaInternados">
                <v-icon>
                    mdi-sync
                </v-icon>
            </v-btn>
        </v-toolbar>
        <div v-if="cargando">
            <v-skeleton-loader
                v-if="cargando"
                type="list-item-two-line, list-item-two-line, list-item-two-line"

            />
        </div>
        <v-alert class="ma-5" type="warning" text v-if="!cargando && internados.length == 0">
            No hay pacientes con nota de egreso
        </v-alert>
        <v-expand-transition>
            <v-list dense v-if="!cargando">
                <v-list-item
                    v-for="itm in internados"
                    :key="itm.id"
                >
                    <v-list-item-icon>
                        {{itm.cama}}
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div>
                                ({{itm.paciente}}) {{itm.pacienteNombre}}
                            </div>
                            <div>
                                {{itm.motivo}}
                            </div>

                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-expand-transition>
    </v-sheet>
</template>

<script>
export default {
    mounted(){
        this.listaInternados()
    },
    data:()=>({
        internados:[],
        cargando:false,
    }),

    methods:{
        async listaInternados(){
            this.cargando=true
            try{
                const req = await this.$http({
                    url:'/hospital/listaEgresos',
                    method:'POST',
                    params:{
                        internado:1,
                        tipo:1,
                    }
                })
                this.cargando=false
                this.internados = req.data.filter(itm=>itm.notaEgreso > 0)
            }catch(err){
                this.cargando=false
                this.$store.dispatch('security/revisaError',err)
            }
        },

    },
    computed:{

    },
}
</script>

<style>

</style>